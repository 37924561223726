@tailwind base;
@tailwind components;
@tailwind utilities;


/*  */

.footer{
    background-image: url('/src/img/pngwing.com\ \(13\)\ 1.png');
     /* background-repeat: no-repeat; */
     background-size: contain;
}


/* landing page */
.circle{
filter: drop-shadow(40px  60px 50px red);
}


